import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import srcOgImage from '../../assets/academy/campaign-og-image.png';
import Footer from '../../components/Academy/Footer';
import SEO from '../../components/SEO';
import styles from './submitted.module.scss';

type DataProps = {
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
};
export const CampaignLandingAcademySubmittedPageQuery = graphql`
  query CampaignLandingAcademySubmittedPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const CampaignLandingAcademySubmittedPage = ({
  data,
}: PageProps<DataProps>) => {
  const { t, originalPath, i18n, navigate } = useI18next();

  return (
    <>
      <SEO
        meta={[
          { name: 'robots', content: 'noindex, follow' },
          { name: 'googlebot', content: 'noindex, follow' },
        ]}
        title={t('campaign-academy-title')}
        description={t('campaign-academy-description')}
        keywords={t('campaign-academy-keywords')}
        lang={'ja'}
        audience={'merchant'}
        ogImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
        twitterImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
      />
      <div className={styles.main}>
        <h1
          dangerouslySetInnerHTML={{
            __html: t('campaign-academy-form-submitted-title'),
          }}
        />
        <p>
          <Trans
            i18nKey="campaign-academy-form-submitted-description"
            components={{ span: <span /> }}
          ></Trans>
        </p>
        <nav className={styles.backLinks}>
          <a
            href="https://academy.smartpay.co"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btn}
          >
            {t('back-to-growth-academy')}
          </a>
          <Link to="/academy/">{t('back')}</Link>
        </nav>
      </div>
      <Footer t={t} />
    </>
  );
};

export default CampaignLandingAcademySubmittedPage;
