import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'gatsby-plugin-react-i18next';
import jsonLd from '../json-ld';

type Meta = {
  name: string;
  content: string;
  property?: string;
};

interface Props {
  description?: string;
  lang?: string;
  meta?: Array<Meta>;
  title?: string;
  keywords?: string;
  ogImage?: string;
  ogURL?: string;
  twitterImage?: string;
  pathname?: string;
  audience?: string;
}

function SEO({
  description = '',
  lang = 'ja',
  meta = [],
  title = 'Smartpay',
  keywords,
  ogImage,
  ogURL,
  twitterImage,
  pathname = '/',
  audience = 'consumer',
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            keywords
            description
            ogImage
            twitterImage
            twitterUsername
          }
        }
      }
    `
  );

  const seo = {
    title:
      title ||
      site.siteMetadata.title ||
      '後払い決済サービス Smartpay(スマートペイ)',
    description: description || site.siteMetadata.description,
    keywords: keywords || site.siteMetadata.keywords,
    ogImage:
      ogImage || `${site.siteMetadata.siteUrl}${site.siteMetadata.ogImage}`,
    ogURL: ogURL || `${site.siteMetadata.siteUrl}${pathname}`,
    twitterImage:
      twitterImage ||
      `${site.siteMetadata.siteUrl}${site.siteMetadata.twitterImage}`,
    twitterUsername: site.siteMetadata.twitterUsername,
  };

  const scripts = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        ...jsonLd,
        description: seo.description,
        keywords: seo.keywords,
        headline: seo.title,
        url: `https://smartpay.co${pathname}`,
        audience,
      }),
    },
  ];

  // TODO: this won't be necessary when the whole website is renewed
  if (!pathname.includes('-2')) {
    scripts.push({
      type: 'javascript',
      innerHTML: `
        (function(d) {
          var config = {
            kitId: 'fgc2ohu',
            scriptTimeout: 3000,
            async: true
          },
          h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
        })(document);
        `,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      meta={(
        [
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: `description`,
            content: seo.description,
          },
          {
            name: 'robots',
            content: 'max-image-preview:large',
          },
          {
            property: `og:title`,
            content: seo.title,
          },
          {
            property: `og:description`,
            content: seo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: 'og:image',
            content: seo.ogImage,
          },
          {
            property: 'og:url',
            content: seo.ogURL,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            property: 'twitter:image',
            content: seo.twitterImage,
          },
          {
            name: `twitter:creator`,
            content: seo.twitterUsername,
          },
          {
            name: `twitter:title`,
            content: seo.title,
          },
          {
            name: `twitter:description`,
            content: seo.description,
          },
          {
            name: 'facebook-domain-verification',
            content: 'nns7i177r5j514ygkdh5w50chtk7v4',
          },
        ] as Array<Meta>
      ).concat(meta)}
      link={[{ rel: 'preconnect', href: 'https://www.google-analytics.com' }]}
      script={scripts}
    />
  );
}

export default SEO;
