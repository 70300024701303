const currentYear = new Date().getFullYear();

const jdonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Smartpay',
  alternateName: 'スマートペイ',
  headline: '欲しい未来の、出発点',
  url: 'https://smartpay.co',
  image: '/logotype.svg',
  author: '@SmartpayJapan',
  copyrightHolder: 'Smartpay K.K.',
  copyrightYear: currentYear,
  audience: 'consumer',
  encodings: 'UTF-8',
  keywords:
    'BNPL, 後払いサービス, 後払い, buy now pay later, 買い物, 決済, 支払い',
  description:
    'Smartpayを導入したショップでは、平均注文金額が45％アップ、コンバージョン率は10％アップするという著しい成果がで出ています。不正や未払いのリスクも解消できます。',
  inLanguage: 'ja',
  locationCreated: {
    address: {
      addressCountry: 'Japan',
      addressRegion: 'Tokyo',
      postalCode: '1070061',
      streetAddress: 'Aoyama Palacio Tower 11F, 3-6-7 Kita Aoyama, Minato-ku',
    },
  },
};

export default jdonLd;
